import React, {useState, useEffect, useRef } from "react";
import '../css/index.css';
import '../css/main.css';
import ReactPlayer from 'react-player';

import LOGO from '../src/logo.svg';
import M1 from '../src/m1.svg';
import M2 from '../src/m2.png';
import B1 from '../src/b1.png';
import B2 from '../src/b2.png';
import Footer from "../elements/footer";
import Bar from "../elements/bar";
import videoSrc from '../src/V.mp4'; // Импортируйте видео
import videoTwo from '../src/V1.mp4'; // Импортируйте видео

function Main() {
    const containerRef = useRef(null);
    const textBlockRef = useRef(null);

    window.addEventListener('DOMContentLoaded', () => {
        const mainBlockText = document.querySelector('.main_block_text');
        mainBlockText.classList.add('appear');
    });
    window.addEventListener('scroll', () => {
        const mainBlockText = document.querySelector('.main_block_text');
        const rect = mainBlockText.getBoundingClientRect();

        if (rect.top < window.innerHeight && rect.bottom > 0) {
            mainBlockText.classList.add('appear');
        }
    });
    useEffect(() => {
        const options = {
            threshold: 0.1, // Adjust this threshold as needed
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in');
                } else {
                    entry.target.classList.remove('fade-in');
                }
            });
        }, options);

        // Observing elements inside containerRef
        if (containerRef.current) {
            const items = containerRef.current.querySelectorAll('.main_block_mz_item');
            items.forEach(item => observer.observe(item));
        }

        // Observing main_block_text and its inner elements
        if (textBlockRef.current) {
            observer.observe(textBlockRef.current); // Observe the entire text block
            const innerItems = textBlockRef.current.querySelectorAll('.f1, .main_contacts, .main_contacts_hor');
            innerItems.forEach(item => observer.observe(item)); // Observe specific elements inside the block
        }

        return () => {
            if (containerRef.current) {
                const items = containerRef.current.querySelectorAll('.main_block_mz_item');
                items.forEach(item => observer.unobserve(item));
            }

            if (textBlockRef.current) {
                observer.unobserve(textBlockRef.current); // Unobserve the text block
                const innerItems = textBlockRef.current.querySelectorAll('.f1, .main_contacts, .main_contacts_hor');
                innerItems.forEach(item => observer.unobserve(item)); // Unobserve the specific elements inside
            }
        };
    }, []);

    return (
        <div className="main">
            <Bar/>
            <div className={"main_block"}>
                <img className={"m1"} src={M1}/>
                <img className={"m2"} src={M2}/>
            </div>
            <div className={"main_block_text"}>

                <div className={"main_block_m1 mbm1"} >
                    <h1>

                        Welcome to Aero Neuron</h1>
                    <h2>Revolutionizing Drone Technology</h2>
                    <p>Welcome to Aero Neuron, where cutting-edge drone technology meets advanced artificial
                        intelligence to provide unparalleled solutions for security, efficiency, and data management.
                        Aero Neuron is a leader in the development and deployment of autonomous drones designed to
                        enhance operations across multiple industries, including public safety, defense, agriculture,
                        construction, and more.</p>
                </div>
              {/*  <div className={"main_block_m2"}>
                    <div className={"main_block_icons"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round"
                             className="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round"
                             className="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"/>
                            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
                            <path d="M16.5 7.5l0 .01"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round"
                             className="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"/>
                        </svg>
                    </div>
                </div>*/}
            </div>
            <div className={"main_block_texta"}>
                <div className="main_block_m3">
                    <video  className={"main_m3_video"} autoPlay loop muted>
                        <source src={videoSrc} type="video/mp4" />
                    </video>
                </div>
                <div className={"main_block_m1 "}>
                    <h1>

                        Our Mission</h1>
                    <p>At Aero Neuron, our mission is to create intelligent, versatile, and reliable drone solutions that
                        empower businesses, governments, and communities to achieve their goals with precision and
                        safety. Our drones are designed to adapt to a wide range of applications, offering real-time data,
                        enhanced security, and seamless integration with existing systems.

                    </p>
                    <div className={"button"}>
                       <a href={"/about"}>
                           Read more
                       </a>
                    </div>
                </div>

            </div>



                <div className={"main_block_mz"}>

                    <div className="main_block_mz_items"  ref={containerRef}>

                        <div className="main_block_mz_item">
                            {/* Ваш контент */}

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="currentColor"
                                 className="icon icon-tabler icons-tabler-filled icon-tabler-section">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path
                                    d="M20.01 19a1 1 0 0 1 .117 1.993l-.127 .007a1 1 0 0 1 -.117 -1.993zm-16 0a1 1 0 0 1 0 2a1 1 0 0 1 -.127 -1.993zm4 0a1 1 0 0 1 0 2a1 1 0 0 1 -.127 -1.993zm4 0a1 1 0 0 1 .117 1.993l-.127 .007a1 1 0 0 1 -.117 -1.993zm4 0a1 1 0 0 1 .117 1.993l-.127 .007a1 1 0 0 1 -.117 -1.993zm4 -16a1 1 0 0 1 .117 1.993l-.127 .007a1 1 0 0 1 -.117 -1.993zm-16 0a1 1 0 1 1 0 2a1 1 0 0 1 -.127 -1.993zm4 0a1 1 0 1 1 0 2a1 1 0 0 1 -.127 -1.993zm4 0a1 1 0 0 1 .117 1.993l-.127 .007a1 1 0 0 1 -.117 -1.993zm3.99 0a1 1 0 0 1 1 1a1 1 0 1 1 -2 .01c0 -.562 .448 -1.01 1 -1.01m3 4a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2z"/>
                            </svg>
                            <h1>Autonomous Operation</h1>
                            <p>Aero Neuron drones are equipped with advanced AI that
                                allows them to operate autonomously, from takeoff to landing and recharging, ensuring
                                continuous surveillance and data collection without human intervention.</p>
                        </div>
                        <div  className="main_block_mz_item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="currentColor"
                                 className="icon icon-tabler icons-tabler-filled icon-tabler-atom-2">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M12 8a4 4 0 1 1 -3.995 4.2l-.005 -.2l.005 -.2a4 4 0 0 1 3.995 -3.8z"/>
                                <path
                                    d="M12 20a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z"/>
                                <path
                                    d="M3 8a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z"/>
                                <path
                                    d="M21 8a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z"/>
                                <path
                                    d="M2.89 12.006a1 1 0 0 1 1.104 .884a8 8 0 0 0 4.444 6.311a1 1 0 1 1 -.876 1.799a10 10 0 0 1 -5.556 -7.89a1 1 0 0 1 .884 -1.103z"/>
                                <path
                                    d="M20.993 12l.117 .006a1 1 0 0 1 .884 1.104a10 10 0 0 1 -5.556 7.889a1 1 0 1 1 -.876 -1.798a8 8 0 0 0 4.444 -6.31a1 1 0 0 1 .987 -.891z"/>
                                <path
                                    d="M5.567 4.226a10 10 0 0 1 12.666 0a1 1 0 1 1 -1.266 1.548a8 8 0 0 0 -10.134 0a1 1 0 1 1 -1.266 -1.548z"/>
                            </svg>
                            <h1>AI Powered Security</h1>
                            <p>Our drones integrate facial recognition, license plate recognition,
                                thermal imaging and laser perimeter control to provide comprehensive security solutions
                                for residential, commercial, and industrial environments.</p>
                        </div>
                        <div className="main_block_mz_item">

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round"
                                 className="icon icon-tabler icons-tabler-outline icon-tabler-drone">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M10 10h4v4h-4z"/>
                                <path d="M10 10l-3.5 -3.5"/>
                                <path d="M9.96 6a3.5 3.5 0 1 0 -3.96 3.96"/>
                                <path d="M14 10l3.5 -3.5"/>
                                <path d="M18 9.96a3.5 3.5 0 1 0 -3.96 -3.96"/>
                                <path d="M14 14l3.5 3.5"/>
                                <path d="M14.04 18a3.5 3.5 0 1 0 3.96 -3.96"/>
                                <path d="M10 14l-3.5 3.5"/>
                                <path d="M6 14.04a3.5 3.5 0 1 0 3.96 3.96"/>
                            </svg>
                            <h1>RealTime Data</h1>
                            <p>Aero Neuron drones capture high-resolution imagery and video,
                                providing real-time insights that enhance decision-making and operational efficiency.</p>
                        </div>
                        <div  className="main_block_mz_item">

                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round"
                                 className="icon icon-tabler icons-tabler-outline icon-tabler-topology-full-hierarchy">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M20 18a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"/>
                                <path d="M8 18a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"/>
                                <path d="M8 6a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"/>
                                <path d="M20 6a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"/>
                                <path d="M14 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z"/>
                                <path d="M6 8v8"/>
                                <path d="M18 16v-8"/>
                                <path d="M8 6h8"/>
                                <path d="M16 18h-8"/>
                                <path d="M7.5 7.5l3 3"/>
                                <path d="M13.5 13.5l3 3"/>
                                <path d="M16.5 7.5l-3 3"/>
                                <path d="M10.5 13.5l-3 3"/>
                            </svg>
                            <h1>Versatility</h1>
                            <p>Designed for multi-purpose use, Aero Neuron drones are ideal for
                                applications in agriculture, environmental monitoring, mining, public safety, and more.</p>
                        </div>
                    </div>
                </div>




       {/*     <div className={"main_block_text"}>
                <div className={"main_block_m1"}>
                    <h1>Industries Served</h1>
                    <p>Aero Neuron’s drones are transforming operations across various industries:
                        <br/>
                        <br/>
                        <h1>•Public Safety</h1>
                        Enhancing incident response, crime scene documentation, and disaster
                        recovery.
                        <br/>
                        <br/>
                        <h1>•Defense and Security</h1>
                        Providing real-time intelligence, tactical mapping, and secure
                        surveillance.
                        <br/>
                        <br/>
                        <h1>•Agriculture</h1>
                        Optimizing crop health monitoring, pest management, and yield prediction.
                        <br/>
                        <br/>
                        <h1>•Construction</h1>
                        Improving site security, progress tracking, and environmental compliance.
                        <br/>
                        <br/>
                        <h1>•Oil and Gas</h1>
                        Enhancing asset monitoring, environmental compliance, and operational
                        efficiency.
                    </p>
                </div>
                <img className={"main_block_m3"} src={B2}/>


            </div>*/}
            <img className={"m1"} src={M1}/>
<div className={"main_video_two"}>
    <video  className={"main_m3_video_two"} autoPlay loop muted>
        <source src={videoTwo} type="video/mp4" />
    </video>
</div>


           {/* <h1 className={"team_title"}>OUR TEAM</h1>
            <div className={'team'}>
                <div className={"team_item"}>
                    <div className={"team_img"}>

                    </div>
                    <div className={"team_text"}>
                        <h1>
                            ALDEN JONES
                        </h1>
                        <h2>
                            CUSTOMER SUCCESS
                        </h2>
                        <p>
                            Founder of American Tower's UAS program,
                            responsible for digitizing portfolio of 40,000
                            US tower assets with 175 pilots. Experience
                            with large scale technology proofs of concept,
                            training, implementation and support.
                            Former US Army Captain.
                        </p>
                    </div>
                </div>
                <div className={"team_item"}>
                    <div className={"team_img"}>

                    </div>
                    <div className={"team_text"}>
                        <h1>
                            HAYK MARTIROS
                        </h1>
                        <h2>
                            HEAD OF AUTONOMY
                        </h2>
                        <p>
                            Seasoned roboticist pushing the limits of
                            visual navigation, 3D reconstruction, and
                            real-time algorithms. Experience across drones,
                            hexapods, robot arms, milli- scale robot
                            swarms, and self- balancing motorcycles.
                            BS from Princeton, MS from Stanford.
                        </p>
                    </div>
                </div>
                <div className={"team_item"}>
                    <div className={"team_img"}>

                    </div>
                    <div className={"team_text"}>
                        <h1>
                            RYAN READING
                        </h1>
                        <h2>
                            HEAD OF SWENGINEERING
                        </h2>
                        <p>
                            Founder of American Tower's UAS program, responsible for digitizing portfolio of 40,000 US
                            tower assets with 175 pilots. Experience with large scale technology proofs of concept,
                            training, implementation and support. Former US Army Captain.
                        </p>
                    </div>
                </div>
                <div className={"team_item"}>
                    <div className={"team_img"}>

                    </div>
                    <div className={"team_text"}>
                        <h1>
                            BEN THOMPSON
                        </h1>
                        <h2>
                            HEAD OF HARDWARE
                        </h2>
                        <p>
                            Founder of American Tower's UAS program,
                            responsible for digitizing portfolio of 40,000
                            US tower assets with 175 pilots. Experience
                            with large scale technology proofs of concept,
                            training, implementation and support.
                            Former US Army Captain.
                        </p>
                    </div>
                </div>
            </div>*/}


            <div ref={textBlockRef}   className={"main_block_text f1 "}>

                <div className={"main_block_m1"} >
                    <h1>Join the Future of Drone Technology</h1>
                    <p>Explore our website to learn more about how Aero Neuron can revolutionize your operations.
                        Whether you’re looking to enhance security, improve efficiency, or gain better insights through
                        real-time data, Aero Neuron has the solution for you. Contact us today to discuss how our drones
                        can meet your specific needs.
                    </p>

                </div>
                <div className={"main_block_m4"}>
                   <div className={"main_contacts"}>
                       <div className={"main_contacts_hor"}>
                           <input placeholder={"Name"}/>
                           <input placeholder={"Phone Number"} type={"tel"}/>
                       </div>
                       <div className={"main_contacts_hor"}>
                       <textarea placeholder={"Message"}></textarea>
                       </div>
                       <div className={"main_contacts_hor"}>
                           <input placeholder={"Email"} type={"email"}/>
                           <button className={"button btnf"}>
                               Send
                           </button>

                       </div>
                   </div>

                </div>
            </div>

            <Footer/>


        </div>
    );
}

export default Main;