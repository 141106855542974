import React, {useState, useEffect, useRef } from "react";
import '../css/index.css';
import '../css/main.css';


import LOGO from '../src/logo.svg';

import D1 from '../src/d1.png';
import D2 from '../src/d2.png';
import D3 from '../src/d3.png';
import D4 from '../src/d4.png';
import D5 from '../src/d5.png';
import D6 from '../src/d6.png';
import Services from "./services";
import Footer from "../elements/footer";
import Bar from "../elements/bar";
function Main() {
    const textBlockRef = useRef(null);
    const containerRef = useRef(null);


    window.addEventListener('DOMContentLoaded', () => {
        const mainBlockText = document.querySelector('.origin');
        mainBlockText.classList.add('fade-in');
    });
    window.addEventListener('scroll', () => {
        const mainBlockText = document.querySelector('.origin');
        const rect = mainBlockText.getBoundingClientRect();

        if (rect.top < window.innerHeight && rect.bottom > 0) {
            mainBlockText.classList.add('fade-in');
        }
    });
    useEffect(() => {
        const options = {
            threshold: 0.1, // Adjust this threshold as needed
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in');
                } else {
                    entry.target.classList.remove('fade-in');
                }
            });
        }, options);

        // Observing elements inside containerRef
        if (containerRef.current) {
            const items = containerRef.current.querySelectorAll('.origin');
            items.forEach(item => observer.observe(item));
        }

        // Observing main_block_text and its inner elements
        if (textBlockRef.current) {
            observer.observe(textBlockRef.current); // Observe the entire text block
            const innerItems = textBlockRef.current.querySelectorAll('.origin');
            innerItems.forEach(item => observer.observe(item)); // Observe specific elements inside the block
        }

        return () => {
            if (containerRef.current) {
                const items = containerRef.current.querySelectorAll('.origin');
                items.forEach(item => observer.unobserve(item));
            }

            if (textBlockRef.current) {
                observer.unobserve(textBlockRef.current); // Unobserve the text block
                const innerItems = textBlockRef.current.querySelectorAll('.origin');
                innerItems.forEach(item => observer.unobserve(item)); // Unobserve the specific elements inside
            }
        };
    }, []);

    const [isTextVisible, setIsTextVisible] = useState(false);
    const [isTextVisible2, setIsTextVisible2] = useState(false);
    const [isTextVisible3, setIsTextVisible3] = useState(false);
    const [isTextVisible4, setIsTextVisible4] = useState(false);
    const [isTextVisible5, setIsTextVisible5] = useState(false);
    const [isTextVisible6, setIsTextVisible6] = useState(false);
    // Function to toggle the visibility
    const handleReadMoreClick = () => {
        setIsTextVisible(!isTextVisible);
        setIsTextVisible2(!isTextVisible2);
    };
    const handleReadMoreClick2 = () => {

        setIsTextVisible2(!isTextVisible2);
    };
    const handleReadMoreClick3 = () => {

        setIsTextVisible3(!isTextVisible3);
    };
    const handleReadMoreClick4 = () => {

        setIsTextVisible4(!isTextVisible4);
    };
    const handleReadMoreClick5 = () => {

        setIsTextVisible5(!isTextVisible5);
    };
    const handleReadMoreClick6 = () => {

        setIsTextVisible6(!isTextVisible6);
    };
    return (
        <div className="main">
           <Bar/>

            <div className={"main_block_text"} >

                <div className={" mz1"}>
                    <h1>DRONES</h1>
                    <p>We offer a range of cutting-edge services designed to enhance productivity, creativity,
                        and safety through autonomous flight technology. Our solutions cater to diverse industries,
                        providing unparalleled efficiency and innovative solutions.</p>

                </div>

            </div>

       <div className={"main_block_text "} ref={containerRef}>
           {/*///////////////////////////////////////////////////////////////*/}

           <div className={"main_block_text origin"} >
               <img className={"main_block_m3"}  src={D1}/>

               <div className={"main_block_m1 mz1"}>
                 <div className={"main_block_mz_title"}>

                     <h1>
                         Security Drone</h1>
                 </div>
                   <h2>Advanced Surveillance and Protection</h2>
                   <p>Aero Neuron’s Security Drones are designed to provide comprehensive surveillance and
                       protection for residential, commercial, and industrial environments. Equipped with AI-driven
                       capabilities, these drones offer real-time monitoring, incident detection, and automated responses
                       to potential threats.
                   </p>
                   <div className={"button"} onClick={handleReadMoreClick}>
                       <a >
                           {isTextVisible ? 'Show Less' : 'Read more'}
                       </a>
                   </div>
               </div>


           </div>
           {isTextVisible && (
               <div  className={"main_block_mz_itemsa"}>
                   <div className={"main_block_mz_itema"}>
                       <h1>Key Features</h1>
                       <p>
                           <h2>Autonomous Operation: </h2> 24/7 surveillance without the need for human intervention.
                       </p>
                       <p>
                           <h2>AI-Powered Monitoring: </h2> Facial recognition, license plate recognition, thermal imaging
                           and behavioral analysis.
                       </p>

                       <p>
                           <h2>Emergency Response: </h2> Automatic alerting of emergency services in case of a security
                           breach or attack.
                       </p>
                   </div>
                   <div className={"main_block_mz_itema"}>
                       <h1>Benefits</h1>
                       <p>
                           <h2>Enhanced Security: </h2> Continuous monitoring and rapid response to potential threats
                       </p>
                       <p>
                           <h2>Cost Efficiency: </h2> Reduces the need for extensive physical security infrastructure.
                       </p>

                       <p>
                           <h2>Real-Time Data: </h2> Provides actionable insights that improve decision-making.
                       </p>
                   </div>

               </div>
           )}


           {/*///////////////////////////////////////////////////////////////*/}


           {/*///////////////////////////////////////////////////////////////*/}

           <div className={"main_block_text origin"}  ref={containerRef}>
               <img className={"main_block_m3"} src={D2}/>

               <div className={"main_block_m1 mz1"}>

                   <h1>
                                            Military and Defense Drone</h1>
                   <h2>Tactical Advantage and Real-Time Intelligence</h2>
                   <p>Aero Neuron’s Military and Defense Drones are engineered to provide critical support in defense
                       operations, offering capabilities such as tactical mapping, reconnaissance, and intelligence
                       gathering. These drones enhance mission effectiveness by providing real-time data and secure
                       communication.
                   </p>
                   <div className={"button"}  onClick={handleReadMoreClick2}>
                       <a >
                           {isTextVisible2 ? 'Show Less' : 'Read more'}
                       </a>
                   </div>
               </div>


           </div>
           {isTextVisible2 && (
               <div className={"main_block_mz_itemsa"}>
                   <div className={"main_block_mz_itema"}>
                       <h1>Key Features</h1>
                       <p>
                           <h2>Tactical Mapping: </h2> Detailed terrain analysis and obstacle detection.
                       </p>
                       <p>
                           <h2>Intelligence Gathering: </h2> Equipped with thermal imaging, multispectral sensors, and AI
                           analysis.
                       </p>

                       <p>
                           <h2>Secure Communication: </h2> Encrypted data transmission to ensure the integrity of sensitive
                           information.
                       </p>
                   </div>
                   <div className={"main_block_mz_itema"}>
                       <h1>Benefits</h1>
                       <p>
                           <h2>Enhanced Security: </h2> Continuous monitoring and rapid response to potential threats
                       </p>
                       <p>
                           <h2>Cost Efficiency: </h2> Reduces the need for extensive physical security infrastructure.
                       </p>

                       <p>
                           <h2>Real-Time Data: </h2> Provides actionable insights that improve decision-making.
                       </p>
                   </div>

               </div>
           )}

           {/*///////////////////////////////////////////////////////////////*/}


           {/*///////////////////////////////////////////////////////////////*/}

           <div className={"main_block_text origin"}>
               <img className={"main_block_m3"} src={D4}/>

               <div className={"main_block_m1 mz1"}>

                   <h1>
                     Police Drone</h1>
                   <h2>Supporting Law Enforcement with Advanced Technology</h2>
                   <p>Aero Neuron’s Police Drones are designed to assist law enforcement agencies in maintaining
                       public safety. From crime scene documentation to incident overwatch, these drones provide the
                       tools needed to enhance policing efforts.
                   </p>
                   <div className={"button"} onClick={handleReadMoreClick3}>
                       <a >
                           {isTextVisible3 ? 'Show Less' : 'Read more'}
                       </a>
                   </div>
               </div>


           </div>

           {isTextVisible3 && (
               <div className={"main_block_mz_itemsa"}>
                   <div className={"main_block_mz_itema"}>
                       <h1>Key Features</h1>
                       <p>
                           <h2>Crime Scene Documentation: </h2> High-resolution imaging for accurate evidence collection.
                       </p>
                       <p>
                           <h2>Incident Overwatch: </h2>  Real-time monitoring of large-scale events or emergencies.
                       </p>

                       <p>
                           <h2>Crowd Monitoring: </h2> Behavioral analysis and automated alerts for suspicious activities.
                       </p>
                   </div>
                   <div className={"main_block_mz_itema"}>
                       <h1>Benefits</h1>
                       <p>
                           <h2>Improved Investigation: </h2> Enhances the accuracy and thoroughness of crime scene
                           documentation.
                       </p>
                       <p>
                           <h2>Public Safety: </h2> Provides law enforcement with real-time data to manage incidents
                           effectively.
                       </p>

                       <p>
                           <h2>Resource Efficiency: </h2> Reduces the need for large physical police presences at events.
                       </p>
                   </div>

               </div>
           )}

           {/*///////////////////////////////////////////////////////////////*/}

           {/*///////////////////////////////////////////////////////////////*/}

           <div className={"main_block_text origin"}>
               <img className={"main_block_m3"} src={D5}/>

               <div className={"main_block_m1 mz1"}>

                   <h1>
                       Fire Fighting Drone</h1>
                   <h2>Enhancing Firefighting Efforts with Real-Time Data</h2>
                   <p>Aero Neuron’s Fire Fighting Drones are invaluable tools in the fight against fires, providing
                       firefighters with real-time data and thermal imaging to assess and manage fire situations
                       effectively.
                   </p>
                   <div className={"button"} onClick={handleReadMoreClick4}>
                       <a>
                           {isTextVisible ? 'Show Less' : 'Read more'}
                       </a>
                   </div>
               </div>


           </div>
           {isTextVisible4 && (
               <div className={"main_block_mz_itemsa"}>
                   <div className={"main_block_mz_itema"}>
                       <h1>Key Features</h1>
                       <p>
                           <h2>Thermal Imaging: </h2> Detects heat sources and monitors fire spread in real-time.
                       </p>
                       <p>
                           <h2>Incident Management: </h2>  Provides a bird’s-eye view of fire scenes for better decision-
                           making.
                       </p>

                       <p>
                           <h2>Autonomous Operation: </h2> Capable of operating in hazardous environments without
                           risking human lives.
                       </p>
                   </div>
                   <div className={"main_block_mz_itema"}>
                       <h1>Benefits</h1>
                       <p>
                           <h2>Increased Safety: </h2> Reduces the need for firefighters to enter dangerous areas.
                       </p>
                       <p>
                           <h2>Enhanced Effectiveness: </h2> Provides critical data that helps in containing and
                           extinguishing fires.
                       </p>

                       <p>
                           <h2>Cost Efficiency: </h2> Minimizes damage by enabling faster, more informed responses.
                       </p>
                   </div>

               </div>
           )}

           {/*///////////////////////////////////////////////////////////////*/}

           {/*///////////////////////////////////////////////////////////////*/}

           <div className={"main_block_text origin"}>
               <img className={"main_block_m3"} src={D3}/>

               <div className={"main_block_m1 mz1"}>

                   <h1>
                      Delivery Drone</h1>
                   <h2>Revolutionizing Delivery with Autonomous Drones</h2>
                   <p>
                       Aero Neuron’s Delivery Drones offer a new frontier in logistics, enabling businesses to deliver
                       goods quickly, safely, and efficiently, even in hard-to-reach areas.
                   </p>
                   <div className={"button"} onClick={handleReadMoreClick5}>
                       <a>
                           {isTextVisible ? 'Show Less' : 'Read more'}
                       </a>
                   </div>
               </div>


           </div>
           {isTextVisible5 && (
               <div className={"main_block_mz_itemsa"}>
                   <div className={"main_block_mz_itema"}>
                       <h1>Key Features</h1>
                       <p>
                           <h2>Autonomous Flight: </h2> Efficient delivery routes managed by AI.
                       </p>
                       <p>
                           <h2>Payload Capacity: </h2>  Capable of carrying various types of goods, from parcels to medical
                           supplies.
                       </p>

                       <p>
                           <h2>Real-Time Tracking: </h2> Provides customers and businesses with real-time delivery
                           updates.
                       </p>
                   </div>
                   <div className={"main_block_mz_itema"}>
                       <h1>Benefits</h1>
                       <p>
                           <h2>Speed: </h2> Significantly reduces delivery times, especially in congested or remote areas.
                       </p>
                       <p>
                           <h2>Cost Reduction: </h2> Lowers transportation costs by reducing the need for traditional
                           delivery vehicles.
                       </p>

                       <p>
                           <h2>Reliability: </h2> Ensures timely deliveries with AI-optimized routes and schedules.
                       </p>
                   </div>

               </div>
           )}

           {/*///////////////////////////////////////////////////////////////*/}


           {/*///////////////////////////////////////////////////////////////*/}

           <div className={"main_block_text origin"}>
               <img className={"main_block_m3"} src={D6}/>

               <div className={"main_block_m1 mz1"}>

                   <h1>
                       Agriculture Drone</h1>
                   <h2>Optimizing Farming Practices with Precision Technology</h2>
                   <p>
                       Aero Neuron’s Agriculture Drones are designed to enhance modern farming by providing
                       detailed insights into crop health, soil conditions, and pest management.
                   </p>
                   <div className={"button"} onClick={handleReadMoreClick6}>
                       <a>
                           {isTextVisible ? 'Show Less' : 'Read more'}
                       </a>
                   </div>
               </div>


           </div>
           {isTextVisible6 && (
               <div className={"main_block_mz_itemsa"}>
                   <div className={"main_block_mz_itema"}>
                       <h1>Key Features</h1>
                       <p>
                           <h2>Crop Scouting: </h2> High-resolution imaging to monitor crop health and growth stages.
                       </p>
                       <p>
                           <h2>Yield Prediction: </h2>  Analyzes growth patterns and environmental factors to estimate yields.
                       </p>

                       <p>
                           <h2>Pest Management: </h2>  Identifies pest infestations early, enabling targeted treatment.
                       </p>
                   </div>
                   <div className={"main_block_mz_itema"}>
                       <h1>Benefits</h1>
                       <p>
                           <h2>Increased Yield: </h2> Improves crop management and productivity.
                       </p>
                       <p>
                           <h2>Cost Savings: </h2>Reduces the need for manual scouting and chemical applications.
                       </p>

                       <p>
                           <h2>Sustainability: </h2> Supports environmentally friendly farming practices through precise
                           interventions.
                       </p>
                   </div>

               </div>
           )}

           {/*///////////////////////////////////////////////////////////////*/}
       </div>





            <div ref={textBlockRef}   className={"main_block_text f1 "}>

                <div className={"main_block_m1"} >
                    <h1>Join the Future of Drone Technology</h1>
                    <p>Explore our website to learn more about how Aero Neuron can revolutionize your operations.
                        Whether you’re looking to enhance security, improve efficiency, or gain better insights through
                        real-time data, Aero Neuron has the solution for you. Contact us today to discuss how our drones
                        can meet your specific needs.
                    </p>

                </div>
                <div className={"main_block_m4"}>
                    <div className={"main_contacts"}>
                        <div className={"main_contacts_hor"}>
                            <input placeholder={"Name"}/>
                            <input placeholder={"Phone Number"} type={"tel"}/>
                        </div>
                        <div className={"main_contacts_hor"}>
                            <textarea placeholder={"Message"}></textarea>
                        </div>
                        <div className={"main_contacts_hor"}>
                            <input placeholder={"Email"} type={"email"}/>
                            <button className={"button btnf"}>
                                Send
                            </button>

                        </div>
                    </div>

                </div>
            </div>
            <br/>
            <br/>

         <Footer/>

        </div>
    );
}

export default Main;