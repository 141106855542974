import React, {useState, useEffect, useRef } from "react";
import '../css/index.css';
import '../css/main.css';
import ReactPlayer from 'react-player';

import LOGO from '../src/logo.svg';
import M1 from '../src/m1.svg';
import M2 from '../src/m2.png';
import B1 from '../src/b1.png';
import B2 from '../src/b2.png';
import Footer from "../elements/footer";
import Bar from "../elements/bar";
import videoSrc from '../src/V.mp4'; // Импортируйте видео
import videoTwo from '../src/VA.mp4'; // Импортируйте видео

function Contacts() {
    const containerRef = useRef(null);
    const textBlockRef = useRef(null);

    window.addEventListener('DOMContentLoaded', () => {
        const mainBlockText = document.querySelector('.main_block_text');
        mainBlockText.classList.add('appear');
    });
    window.addEventListener('scroll', () => {
        const mainBlockText = document.querySelector('.main_block_text');
        const rect = mainBlockText.getBoundingClientRect();

        if (rect.top < window.innerHeight && rect.bottom > 0) {
            mainBlockText.classList.add('appear');
        }
    });
    useEffect(() => {
        const options = {
            threshold: 0.1, // Adjust this threshold as needed
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in');
                } else {
                    entry.target.classList.remove('fade-in');
                }
            });
        }, options);

        // Observing elements inside containerRef
        if (containerRef.current) {
            const items = containerRef.current.querySelectorAll('.main_block_mz_item');
            items.forEach(item => observer.observe(item));
        }

        // Observing main_block_text and its inner elements
        if (textBlockRef.current) {
            observer.observe(textBlockRef.current); // Observe the entire text block
            const innerItems = textBlockRef.current.querySelectorAll('.f1, .main_contacts, .main_contacts_hor');
            innerItems.forEach(item => observer.observe(item)); // Observe specific elements inside the block
        }

        return () => {
            if (containerRef.current) {
                const items = containerRef.current.querySelectorAll('.main_block_mz_item');
                items.forEach(item => observer.unobserve(item));
            }

            if (textBlockRef.current) {
                observer.unobserve(textBlockRef.current); // Unobserve the text block
                const innerItems = textBlockRef.current.querySelectorAll('.f1, .main_contacts, .main_contacts_hor');
                innerItems.forEach(item => observer.unobserve(item)); // Unobserve the specific elements inside
            }
        };
    }, []);

    return (
        <div className="main">
            <Bar/>
            <div className={"main_block"}>

            </div>
            <div className={"main_video_two"}>
                <video  className={"main_m3_video_two"} autoPlay loop muted>
                    <source src={videoTwo} type="video/mp4" />
                </video>
            </div>

            <div ref={textBlockRef}   className={"main_block_text f1 "}>

                <div className={"main_block_m1"} >
                    <h1>Join the Future of Drone Technology</h1>
                    <p>Explore our website to learn more about how Aero Neuron can revolutionize your operations.
                        Whether you’re looking to enhance security, improve efficiency, or gain better insights through
                        real-time data, Aero Neuron has the solution for you. Contact us today to discuss how our drones
                        can meet your specific needs.
                    </p>

                </div>
                <div className={"main_block_m4"}>
                    <div className={"main_contacts"}>
                        <div className={"main_contacts_hor"}>
                            <input placeholder={"Name"}/>
                            <input placeholder={"Phone Number"} type={"tel"}/>
                        </div>
                        <div className={"main_contacts_hor"}>
                            <textarea placeholder={"Message"}></textarea>
                        </div>
                        <div className={"main_contacts_hor"}>
                            <input placeholder={"Email"} type={"email"}/>
                            <button className={"button btnf"}>
                                Send
                            </button>

                        </div>
                    </div>

                </div>
            </div>

            <Footer/>


        </div>
    );
}

export default Contacts;