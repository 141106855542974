import React from "react";
import '../css/index.css';
import '../css/main.css';


import LOGO from '../src/logo.svg';
import M1 from '../src/m1.svg';
import С0 from '../src/с0.png';
import C1 from '../src/c1.png';
import C2 from '../src/c2.png';
import C3 from '../src/c3.png';
import C4 from '../src/c4.png';
import C5 from '../src/c5.png';
import C6 from '../src/c6.png';
import C7 from '../src/c7.png';
import C8 from '../src/c8.png';
import Footer from "../elements/footer";
import Bar from "../elements/bar";

function Company() {


    return (
        <div className="main">
            <Bar/>





            {/*///////////////////////////////////////////////////////////////*/}

            <div className={"main_block_text"}>
                <img className={"main_block_m3"} src={С0}/>

                <div className={"main_block_m1 mz1"}>
                    <h1>Residential Security Drones</h1>
                    <h2>Securing Homes and Communities with Advanced Technology</h2>
                    <p>
                        Aero Neuron’s drones offer unparalleled security for residential areas, from single homes to large estates and gated communities, ensuring the safety and security of residents with advanced surveillance capabilities and AI-driven response systems.
                    </p>
                </div>
            </div>

            <div className={"main_block_mz_itemsa"}>
                <div className={"main_block_mz_itema"}>
                    <h1>Applications</h1>
                    <p>
                        <h2>Perimeter Surveillance: </h2> Continuous monitoring of property boundaries to detect unauthorized access.
                    </p>
                    <p>
                        <h2>Emergency Response: </h2> Automatic alerting of emergency services in case of intrusions or threats.
                    </p>
                    <p>
                        <h2>Behavioral Analysis: </h2> Identifies suspicious behavior and alerts homeowners or security personnel.
                    </p>
                </div>
                <div className={"main_block_mz_itema"}>
                    <h1>Benefits</h1>
                    <p>
                        <h2>Enhanced Security: </h2> Provides a robust security solution that operates autonomously.
                    </p>
                    <p>
                        <h2>Peace of Mind: </h2> Ensures that residents feel safe and secure in their homes.
                    </p>
                    <p>
                        <h2>Cost Efficiency: </h2> Reduces the need for on-site security personnel.
                    </p>
                </div>
            </div>

            {/*///////////////////////////////////////////////////////////////*/}

            <div className={"main_block_text"}>
                <img className={"main_block_m3"} src={C5}/>

                <div className={"main_block_m1 mz1"}>
                    <h1>Construction Drones</h1>
                    <h2>Optimizing Construction Projects with Advanced Technology</h2>
                    <p>
                        Aero Neuron’s drones play a critical role in modern construction projects by providing real-time data, enhancing site security, and improving project management.
                    </p>
                </div>
            </div>

            <div className={"main_block_mz_itemsa"}>
                <div className={"main_block_mz_itema"}>
                    <h1>Applications</h1>
                    <p>
                        <h2>Site Security: </h2> Monitors construction sites to prevent theft, vandalism, and unauthorized access.
                    </p>
                    <p>
                        <h2>Progress Tracking: </h2> Provides aerial views of site progress, helping project managers stay on schedule.
                    </p>
                    <p>
                        <h2>Environmental Monitoring: </h2> Ensures compliance with environmental regulations by monitoring site conditions.
                    </p>
                </div>
                <div className={"main_block_mz_itema"}>
                    <h1>Benefits</h1>
                    <p>
                        <h2>Time Savings: </h2> Speeds up project timelines by providing instant access to critical data.
                    </p>
                    <p>
                        <h2>Increased Safety: </h2> Reduces the need for manual inspections in hazardous areas.
                    </p>
                    <p>
                        <h2>Cost Reduction: </h2> Minimizes losses due to theft, delays, and non-compliance with regulations.
                    </p>
                </div>
            </div>

            {/*///////////////////////////////////////////////////////////////*/}

            <div className={"main_block_text"}>
                <img className={"main_block_m3"} src={C2}/>

                <div className={"main_block_m1 mz1"}>
                    <h1>Defense & Security Drones</h1>
                    <h2>Strengthening National Security with Advanced Drone Solutions</h2>
                    <p>
                        Aero Neuron’s drones are designed to meet the complex needs of defense and security operations, offering real-time intelligence, secure communications, and tactical support.
                    </p>
                </div>
            </div>

            <div className={"main_block_mz_itemsa"}>
                <div className={"main_block_mz_itema"}>
                    <h1>Applications</h1>
                    <p>
                        <h2>Tactical Mapping: </h2> Provides detailed terrain analysis for mission planning.
                    </p>
                    <p>
                        <h2>Surveillance: </h2> Continuous monitoring of borders, military bases, and high-security areas.
                    </p>
                    <p>
                        <h2>Emergency Response: </h2> Rapid deployment during emergencies, including natural disasters and security breaches.
                    </p>
                </div>
                <div className={"main_block_mz_itema"}>
                    <h1>Benefits</h1>
                    <p>
                        <h2>Improved Security: </h2> Enhances national defense capabilities through real-time intelligence and monitoring.
                    </p>
                    <p>
                        <h2>Mission Effectiveness: </h2> Supports military operations with accurate data and secure communications.
                    </p>
                    <p>
                        <h2>Operational Efficiency: </h2> Reduces the need for ground patrols and manned surveillance missions.
                    </p>
                </div>
            </div>

            {/*///////////////////////////////////////////////////////////////*/}

            <div className={"main_block_text"}>
                <img className={"main_block_m3"} src={C1}/>

                <div className={"main_block_m1 mz1"}>
                    <h1>Environmental Monitoring Drones</h1>
                    <h2>Protecting the Environment with Precision Technology</h2>
                    <p>
                        Aero Neuron’s drones are instrumental in environmental monitoring and conservation efforts, providing detailed data that helps manage natural resources and protect ecosystems.
                    </p>
                </div>
            </div>

            <div className={"main_block_mz_itemsa"}>
                <div className={"main_block_mz_itema"}>
                    <h1>Applications</h1>
                    <p>
                        <h2>Vegetation Monitoring: </h2> Assesses plant health and detects changes in vegetation cover.
                    </p>
                    <p>
                        <h2>Species Identification: </h2> Identifies and tracks wildlife populations, including endangered species.
                    </p>
                    <p>
                        <h2>Risk Management: </h2> Monitors for potential environmental hazards, such as wildfires and floods.
                    </p>
                </div>
                <div className={"main_block_mz_itema"}>
                    <h1>Benefits</h1>
                    <p>
                        <h2>Sustainability: </h2> Supports conservation efforts by providing accurate data for informed decision-making.
                    </p>
                    <p>
                        <h2>Efficiency: </h2> Streamlines environmental monitoring, reducing the need for manual fieldwork.
                    </p>
                    <p>
                        <h2>Cost Savings: </h2> Lowers the cost of environmental management by reducing labor and resource requirements.
                    </p>
                </div>
            </div>

            {/*///////////////////////////////////////////////////////////////*/}

            <div className={"main_block_text"}>
                <img className={"main_block_m3"} src={C6}/>

                <div className={"main_block_m1 mz1"}>
                    <h1>Mining and Quarry Drones</h1>
                    <h2>Enhancing Mining Operations with Real-Time Data</h2>
                    <p>
                        Aero Neuron’s drones are transforming the mining and quarrying industries by providing detailed, real-time data that improves efficiency, safety, and profitability.
                    </p>
                </div>
            </div>

            <div className={"main_block_mz_itemsa"}>
                <div className={"main_block_mz_itema"}>
                    <h1>Applications</h1>
                    <p>
                        <h2>Stockpile Measurement: </h2> Accurately measures stockpile volumes to optimize resource management.
                    </p>
                    <p>
                        <h2>Site Monitoring: </h2> Provides continuous surveillance of mining sites, including slope stability and tailings facilities.
                    </p>
                    <p>
                        <h2>Asset Management: </h2> Monitors valuable mining equipment and infrastructure to prevent theft and damage.
                    </p>
                </div>
                <div className={"main_block_mz_itema"}>
                    <h1>Benefits</h1>
                    <p>
                        <h2>Increased Efficiency: </h2> Streamlines mining operations with precise data and automated processes.
                    </p>
                    <p>
                        <h2>Improved Safety: </h2> Reduces the need for manual inspections in hazardous areas.
                    </p>
                    <p>
                        <h2>Cost Reduction: </h2> Lowers operational costs by optimizing resource management and reducing downtime.
                    </p>
                </div>
            </div>

            {/*///////////////////////////////////////////////////////////////*/}

            <div className={"main_block_text"}>
                <img className={"main_block_m3"} src={C3}/>

                <div className={"main_block_m1 mz1"}>
                    <h1>Oil and Gas Drones</h1>
                    <h2>Optimizing Oil and Gas Operations with Advanced Technology</h2>
                    <p>
                        Aero Neuron’s drones provide critical support for the oil and gas industry, enhancing safety, efficiency, and environmental compliance across upstream, midstream, and downstream operations.
                    </p>
                </div>
            </div>

            <div className={"main_block_mz_itemsa"}>
                <div className={"main_block_mz_itema"}>
                    <h1>Applications</h1>
                    <p>
                        <h2>Pipelines Inspection: </h2> Monitors pipelines for leaks, corrosion, and other potential issues.
                    </p>
                    <p>
                        <h2>Offshore Monitoring: </h2> Provides real-time data on offshore platforms, including equipment status and environmental conditions.
                    </p>
                    <p>
                        <h2>Asset Management: </h2> Tracks and monitors valuable oil and gas infrastructure to prevent theft and damage.
                    </p>
                </div>
                <div className={"main_block_mz_itema"}>
                    <h1>Benefits</h1>
                    <p>
                        <h2>Enhanced Safety: </h2> Reduces the risk of accidents and environmental hazards.
                    </p>
                    <p>
                        <h2>Increased Efficiency: </h2> Improves operational efficiency by providing real-time data for decision-making.
                    </p>
                    <p>
                        <h2>Cost Savings: </h2> Lowers operational costs by reducing the need for manual inspections and minimizing downtime.
                    </p>
                </div>
            </div>

            {/*///////////////////////////////////////////////////////////////*/}

            <div className={"main_block_text"}>
                <img className={"main_block_m3"} src={C7}/>

                <div className={"main_block_m1 mz1"}>
                    <h1>Solar Assets Drones</h1>
                    <h2>Maximizing Solar Energy Efficiency with Advanced Drone Solutions</h2>
                    <p>
                        Aero Neuron’s drones provide essential services for solar energy companies, helping to optimize the performance and longevity of solar assets through detailed inspections and real-time data analysis.
                    </p>
                </div>
            </div>

            <div className={"main_block_mz_itemsa"}>
                <div className={"main_block_mz_itema"}>
                    <h1>Applications</h1>
                    <p>
                        <h2>Panel Inspections: </h2> Detects and analyzes defects in solar panels, including hot spots and micro-cracks.
                    </p>
                    <p>
                        <h2>Site Assessments: </h2> Provides detailed surveys of potential solar farm locations to optimize layout and efficiency.
                    </p>
                    <p>
                        <h2>O&M Monitoring: </h2> Supports ongoing operation and maintenance efforts by providing real-time data on solar asset performance.
                    </p>
                </div>
                <div className={"main_block_mz_itema"}>
                    <h1>Benefits</h1>
                    <p>
                        <h2>Improved Efficiency: </h2> Enhances solar energy production by optimizing asset performance.
                    </p>
                    <p>
                        <h2>Cost Reduction: </h2> Lowers maintenance costs by identifying issues before they escalate.
                    </p>
                    <p>
                        <h2>Longevity: </h2> Extends the life of solar assets through proactive monitoring and maintenance.
                    </p>
                </div>
            </div>

            {/*///////////////////////////////////////////////////////////////*/}

            <div className={"main_block_text"}>
                <img className={"main_block_m3"} src={C4}/>

                <div className={"main_block_m1 mz1"}>
                    <h1>Telecommunications Drones</h1>
                    <h2>Revolutionizing Telecom Operations with Aerial Solutions</h2>
                    <p>
                        Aero Neuron’s drones are transforming the telecommunications industry by providing critical support for infrastructure inspections, network maintenance, and site assessments.
                    </p>
                </div>
            </div>

            <div className={"main_block_mz_itemsa"}>
                <div className={"main_block_mz_itema"}>
                    <h1>Applications</h1>
                    <p>
                        <h2>Infrastructure Inspections: </h2> Inspects telecom towers and equipment for damage, wear, and potential hazards.
                    </p>
                    <p>
                        <h2>Network Maintenance: </h2> Provides real-time data to support network maintenance and upgrades.
                    </p>
                    <p>
                        <h2>Site Assessments: </h2> Conducts detailed surveys of potential tower sites to ensure optimal placement and performance.
                    </p>
                </div>
                <div className={"main_block_mz_itema"}>
                    <h1>Benefits</h1>
                    <p>
                        <h2>Operational Efficiency: </h2> Reduces downtime and improves network performance through proactive maintenance.
                    </p>
                    <p>
                        <h2>Cost Savings: </h2> Lowers inspection and maintenance costs by utilizing drone technology.
                    </p>
                    <p>
                        <h2>Enhanced Safety: </h2> Minimizes the risk of accidents and injuries by reducing the need for manual inspections.
                    </p>
                </div>
            </div>

            {/*///////////////////////////////////////////////////////////////*/}

            <div className={"main_block_text"}>
                <img className={"main_block_m3"} src={C8}/>

                <div className={"main_block_m1 mz1"}>
                    <h1>Utility Industry Drones</h1>
                    <h2>Transforming Utilities with Advanced Aerial Solutions</h2>
                    <p>
                        Aero Neuron’s drones provide essential services for the utility industry, helping to optimize the performance and reliability of electricity distribution, transmission, and facilities through detailed inspections and real-time data analysis.
                    </p>
                </div>
            </div>

            <div className={"main_block_mz_itemsa"}>
                <div className={"main_block_mz_itema"}>
                    <h1>Applications</h1>
                    <p>
                        <h2>Line Inspections: </h2> Inspects power lines for damage, corrosion, and potential hazards.
                    </p>
                    <p>
                        <h2>Substation Monitoring: </h2> Provides real-time data on the condition and performance of substations.
                    </p>
                    <p>
                        <h2>Vegetation Management: </h2> Monitors vegetation near power lines to prevent outages and ensure safety.
                    </p>
                </div>
                <div className={"main_block_mz_itema"}>
                    <h1>Benefits</h1>
                    <p>
                        <h2>Increased Reliability: </h2> Enhances the reliability of utility services by preventing outages and reducing downtime.
                    </p>
                    <p>
                        <h2>Cost Savings: </h2> Lowers maintenance costs by utilizing drone technology for inspections and monitoring.
                    </p>
                    <p>
                        <h2>Enhanced Safety: </h2> Minimizes the risk of accidents and injuries by reducing the need for manual inspections.
                    </p>
                </div>
            </div>

            {/*///////////////////////////////////////////////////////////////*/}











































            <br/>
            <div className={"main_block_text"}>

                <div className={"main_block_m1"}>
                    <h1>CONTACT US</h1>
                    <p>Welcome to our company specializing in innovative drone
                        manufacturing and drone software development. We combine
                        high technology, engineering excellence and creativity to offer
                        customers unique and advanced solutions in the field of
                        unmanned systems.

                    </p>

                </div>
                <div className={"main_block_m4"}>
                    <div className={"main_contacts"}>
                        <div className={"main_contacts_hor"}>
                            <input placeholder={"NAME"}/>
                            <input placeholder={"PHONE NUMBER"} type={"tel"}/>
                        </div>
                        <div className={"main_contacts_hor"}>
                            <textarea placeholder={"MESSAGE"}></textarea>
                        </div>
                        <div className={"main_contacts_hor"}>
                            <input placeholder={"EMAIL"} type={"email"}/>
                            <button>
                                SEND
                            </button>

                        </div>
                    </div>

                </div>
            </div>
            <br/>
            <br/>
           <Footer/>


        </div>
    );
}

export default Company;