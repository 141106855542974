import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './css/index.css';

import Main from "./pages/main";
import Services from "./pages/services";
import Company from "./pages/company";
import About from "./pages/about";
import Contacts from "./pages/contacts";



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/drones" element={<Services />} />
            <Route path="/services" element={<Company />} />
            <Route path="/about" element={<About />} />
            <Route path="/contacts" element={<Contacts />} />
        </Routes>
    </Router>
);

