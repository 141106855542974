import React, {useState, useEffect} from "react";
import '../css/index.css';
import '../css/main.css';


import LOGOTYPE from '../src/logotype.svg';
import LOGO from "../src/logo.svg";
import '../css/bar.css';

function Bar() {
    const [isShown, setIsShown] = useState(false);



    const togglePanel = async () => {
        setIsShown(!isShown);
    };

    return (
        <div className={"bar"}>
            <a href={"/"}><img className={"logo"}  src={LOGO}/></a>
            <div className={"menu"}>
                <ul>
                    <li><a href={"/about"}>ABOUT US</a></li>
                    <li><a href={"/drones"}>DRONES</a></li>
                    <li><a href={"/services"}>SERVICES</a></li>
                    <li><a href={"/contacts"}>CONTACTS</a></li>

                </ul>
            </div>
            <div className="IconsR">
                <div className="Icon">
                    <div className="Burger" onClick={({ target }) => target.classList.toggle('active')}>
                        <div className="burgerICO" onClick={togglePanel}>
                            <svg id="line" className="ham hamRotate ham1 null" viewBox="0 0 100 100" width="57">
                                <path className="line top path" d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path>
                                <path className="line middle path" d="m 30,50 h 40"></path>
                                <path className="line bottom path" d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>


            {isShown && (
                <div className="Panel">
                    <ul>
                        <li><a href={"/about"}>ABOUT US</a></li>
                        <li><a href={"/drones"}>DRONES</a></li>
                        <li><a href={"/services"}>SERVICES</a></li>
                        <li><a href={"/contacts"}>CONTACTS</a></li>
                    </ul>
                    <div className={"main_block_m2"}>
                        <div className={"main_block_icons"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round"
                                 className="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round"
                                 className="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"/>
                                <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
                                <path d="M16.5 7.5l0 .01"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round"
                                 className="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"/>
                            </svg>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default Bar;