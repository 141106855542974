import React from "react";
import '../css/index.css';
import '../css/main.css';


import LOGO from '../src/logo.svg';

import D1 from '../src/d1.png';
import D2 from '../src/d2.png';
import D3 from '../src/d3.png';
import D4 from '../src/d4.png';
import Services from "./services";
import Footer from "../elements/footer";
import Bar from "../elements/bar";
import videoTwo from "../src/VVVA.mp4";
function About() {


    return (
        <div className="main">
           <Bar/>

            <div className={"main_block_text"}>

                <div className={"main_block_m1 mbm1"} >
                    <h1>ABOUT AERO NEURON</h1>
                    <p>


                        With a focus on cutting-edge technology and user-centric design, we strive to provide solutions that meet the highest standards
                        of performance and reliability. Our team of experts continuously pushes the boundaries of what’s possible, ensuring our clients
                        benefit from the latest advancements and the most effective tools for their unique needs.
                        <br/><br/>
                        Join us in shaping the future with technology that makes a difference.

                    </p>
                </div>
                <div className={"main_video"}>
                    <video  className={"main_m3_video_two"} autoPlay loop muted>
                        <source src={videoTwo} type="video/mp4" />
                    </video>
                </div>
                {/*  <div className={"main_block_m2"}>
                    <div className={"main_block_icons"}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round"
                             className="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round"
                             className="icon icon-tabler icons-tabler-outline icon-tabler-brand-instagram">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"/>
                            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"/>
                            <path d="M16.5 7.5l0 .01"/>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                             stroke-linejoin="round"
                             className="icon icon-tabler icons-tabler-outline icon-tabler-brand-facebook">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"/>
                        </svg>
                    </div>
                </div>*/}
            </div>
            <div className={"main_block_text"}>

                <div className={"main_block_ma"}>
                   <div className={"main_block_about"}>
                       <h1>
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-eye">
                               <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                               <path
                                   d="M12 4c4.29 0 7.863 2.429 10.665 7.154l.22 .379l.045 .1l.03 .083l.014 .055l.014 .082l.011 .1v.11l-.014 .111a.992 .992 0 0 1 -.026 .11l-.039 .108l-.036 .075l-.016 .03c-2.764 4.836 -6.3 7.38 -10.555 7.499l-.313 .004c-4.396 0 -8.037 -2.549 -10.868 -7.504a1 1 0 0 1 0 -.992c2.831 -4.955 6.472 -7.504 10.868 -7.504zm0 5a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z"/>
                           </svg>Our Vision</h1>
                       <p>
                           At Aero Neuron, we envision a world where advanced drone technology seamlessly integrates
                           with everyday operations, transforming the way businesses and governments manage their
                           assets, security, and data. We strive to be at the forefront of innovation, continuously pushing the
                           boundaries of what’s possible with drone technology
                       </p>
                   </div>
                    <div className={"main_block_about"}>
                    <h1>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-flag">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M4 5a1 1 0 0 1 .3 -.714a6 6 0 0 1 8.213 -.176l.351 .328a4 4 0 0 0 5.272 0l.249 -.227c.61 -.483 1.527 -.097 1.61 .676l.005 .113v9a1 1 0 0 1 -.3 .714a6 6 0 0 1 -8.213 .176l-.351 -.328a4 4 0 0 0 -5.136 -.114v6.552a1 1 0 0 1 -1.993 .117l-.007 -.117v-16z"/>
                        </svg>Our Mission</h1>
                    <p>
                        Our mission is to provide cutting-edge drone solutions that empower our clients to operate more
                        efficiently, securely, and sustainably. By combining advanced artificial intelligence with robust
                        drone capabilities, Aero Neuron delivers unparalleled value across a wide range of industries.
                    </p>
                    </div>
                    <div className={"main_block_about"}>
                    <h1>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-book">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M12.088 4.82a10 10 0 0 1 9.412 .314a1 1 0 0 1 .493 .748l.007 .118v13a1 1 0 0 1 -1.5 .866a8 8 0 0 0 -8 0a1 1 0 0 1 -1 0a8 8 0 0 0 -7.733 -.148l-.327 .18l-.103 .044l-.049 .016l-.11 .026l-.061 .01l-.117 .006h-.042l-.11 -.012l-.077 -.014l-.108 -.032l-.126 -.056l-.095 -.056l-.089 -.067l-.06 -.056l-.073 -.082l-.064 -.089l-.022 -.036l-.032 -.06l-.044 -.103l-.016 -.049l-.026 -.11l-.01 -.061l-.004 -.049l-.002 -.068v-13a1 1 0 0 1 .5 -.866a10 10 0 0 1 9.412 -.314l.088 .044l.088 -.044z"/>
                        </svg>Our History</h1>
                    <p>
                        Founded by industry pioneers with a passion for innovation, Aero Neuron has quickly
                        established itself as a leader in the drone technology space. From our early days of research and
                        development to our current position as a trusted provider of intelligent drone solutions, we have
                        always prioritized quality, reliability, and customer satisfaction.
            R        </p>
                    </div>
                    <div className={"main_block_about"}>
                    <h1>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-star">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z"/>
                        </svg>Leadership Team</h1>
                    <p>
                        Our leadership team consists of experts in AI, engineering, and aerospace, all dedicated to
                        driving the future of drone technology. Their combined experience and vision have been
                        instrumental in developing Aero Neuron’s state-of-the-art drones, ensuring that our products
                        meet the highest standards of performance and safety.
                    </p>
                    </div>
                    <div className={"main_block_about"}>
                    <h1>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-settings">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M14.647 4.081a.724 .724 0 0 0 1.08 .448c2.439 -1.485 5.23 1.305 3.745 3.744a.724 .724 0 0 0 .447 1.08c2.775 .673 2.775 4.62 0 5.294a.724 .724 0 0 0 -.448 1.08c1.485 2.439 -1.305 5.23 -3.744 3.745a.724 .724 0 0 0 -1.08 .447c-.673 2.775 -4.62 2.775 -5.294 0a.724 .724 0 0 0 -1.08 -.448c-2.439 1.485 -5.23 -1.305 -3.745 -3.744a.724 .724 0 0 0 -.447 -1.08c-2.775 -.673 -2.775 -4.62 0 -5.294a.724 .724 0 0 0 .448 -1.08c-1.485 -2.439 1.305 -5.23 3.744 -3.745a.722 .722 0 0 0 1.08 -.447c.673 -2.775 4.62 -2.775 5.294 0zm-2.647 4.919a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z"/>
                        </svg>Technology and Innovation</h1>
                    <p>
                        Aero Neuron is committed to continuous innovation. Our proprietary artificial intelligence is at
                        the core of our drone systems, enabling features like autonomous flight, real-time data analysis,
                        and proactive security measures. We invest heavily in research and development to ensure that
                        our drones remain at the cutting edge of technology, capable of meeting the evolving needs of
                        our clients.
                    </p>
                    </div>
                    <div className={"main_block_about"}>
                        <h1>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="currentColor"
                                 className="icon icon-tabler icons-tabler-filled icon-tabler-alert-square-rounded">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path
                                    d="M12 2l.642 .005l.616 .017l.299 .013l.579 .034l.553 .046c4.687 .455 6.65 2.333 7.166 6.906l.03 .29l.046 .553l.041 .727l.006 .15l.017 .617l.005 .642l-.005 .642l-.017 .616l-.013 .299l-.034 .579l-.046 .553c-.455 4.687 -2.333 6.65 -6.906 7.166l-.29 .03l-.553 .046l-.727 .041l-.15 .006l-.617 .017l-.642 .005l-.642 -.005l-.616 -.017l-.299 -.013l-.579 -.034l-.553 -.046c-4.687 -.455 -6.65 -2.333 -7.166 -6.906l-.03 -.29l-.046 -.553l-.041 -.727l-.006 -.15l-.017 -.617l-.004 -.318v-.648l.004 -.318l.017 -.616l.013 -.299l.034 -.579l.046 -.553c.455 -4.687 2.333 -6.65 6.906 -7.166l.29 -.03l.553 -.046l.727 -.041l.15 -.006l.617 -.017c.21 -.003 .424 -.005 .642 -.005zm.01 13l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -8a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z"/>
                            </svg>Corporate Responsibility</h1>
                        <p>
                            At Aero Neuron, we prioritize corporate responsibility and sustainability. We're committed to reducing our environmental impact through energy-efficient drone technology and sustainable practices. We partner with like-minded organizations, upholding integrity, transparency, and social responsibility, all while striving to make a positive societal impact.

                        </p>
                    </div>
                </div>

            </div>





            <div className={"main_block_text"}>

                <div className={"main_block_m1"}>
                    <h1>Join the Future of Drone Technology</h1>
                    <p>Explore our website to learn more about how Aero Neuron can revolutionize your operations.
                        Whether you’re looking to enhance security, improve efficiency, or gain better insights through
                        real-time data, Aero Neuron has the solution for you. Contact us today to discuss how our drones
                        can meet your specific needs.
                    </p>

                </div>
                <div className={"main_block_m4"}>
                    <div className={"main_contacts"}>
                        <div className={"main_contacts_hor"}>
                            <input placeholder={"NAME"}/>
                            <input placeholder={"PHONE NUMBER"} type={"tel"}/>
                        </div>
                        <div className={"main_contacts_hor"}>
                            <textarea placeholder={"MESSAGE"}></textarea>
                        </div>
                        <div className={"main_contacts_hor"}>
                            <input placeholder={"EMAIL"} type={"email"}/>
                            <button>
                                SEND
                            </button>

                        </div>
                    </div>

                </div>
            </div>
            <br/>
            <br/>

         <Footer/>

        </div>
    );
}

export default About;